<!--  -->
<template>
  <div class="password card">
    <div class="password-top">
      <p class="id-card">修改密码</p>
    </div>
    <div class="password-form">
      <el-form ref="form">
        <el-input
          placeholder="请输入手机号"
          prefix-icon="el-icon-mobile-phone"
          v-model="phone"
        >
          <i slot="suffix" class="el-icon-error" @click="showPwd"></i>
        </el-input>
        <el-input
          placeholder="请输入验证码"
          prefix-icon="el-icon-chat-dot-square"
          v-model="mycode"
        >
        </el-input>
        <el-input
          placeholder="请设置新密码"
          prefix-icon="el-icon-lock"
          type="password"
          v-model="password"
        >
        </el-input>
         <el-input
          placeholder="请确认新密码"
          prefix-icon="el-icon-lock"
          type="password"
          v-model="newPassword"
        >
        </el-input>
        <el-button type="primary" size="mini" plain round class="buttom-code" @click="getCode" :disabled="disabled">{{loginMessage}}</el-button>
      </el-form>
      <el-button type="primary" round size="medium" @click="changePassword"> 确认修改 </el-button>
    </div>
      <div class="bj" v-if="yp">
        <yp @onSuccess="YpSuccess"></yp>
    </div>
  </div>
</template>
 
<script>
import Yp from '../../components/Yp'

export default {
  name: "password",
  data() {
    return {
      disabled:false,
       yp: false,
        tokens: '',
        phone: "",
        mycode: "",
        password: "",
        newPassword:'',
        loginMessage:'获取验证码'
    };
  },
  components: {
    Yp
  },
  methods: {
      // 公共url方法
    getCommpara() {
      const commpara = this.$route.params;
      return commpara;
    },
      // 确认修改
    changePassword() {
        var that = this;
        let rule = /^1[3|4|5|7|8][0-9]{9}$/;
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if(that.mycode.length < 4){
        that.$message.warning('请输入正确的验证码');return;
      }
      if(!reg.test(that.password)){
        that.$message.warning('密码至少包含 数字和英文，长度6-20');return;
      }
       if(that.newPassword != that.password){
        that.$message.warning('两次密码不一致');return;
      }
      let commpara = that.getCommpara()
      that.$post('api/user/reset_pwd', {phonenum:that.phone,vcode:that.mycode,password:that.newPassword,reg_type:2}, commpara).then(res => {
        if (res.code == 0) {
          that.$message.success('修改成功');
          that.$router.go(0)
        }else{
          that.$message.error(res.message);
        }
      })
    },
    // 获取验证码
    getCode() {
      let that = this;
      let rule = /^1[3|4|5|7|8][0-9]{9}$/;
      if (!rule.test(that.phone)) {
        that.$message.warning("请填写正确手机号码");
        return;
      }
      this.yp = true
    },
    // 请求二维码接口
    codeM() {
      var that = this
         const commpara = that.getCommpara();
      that
        .$post("api/verify_code", { target: that.phone, type: 1,token:this.tokens }, commpara)
        .then((res) => {
          console.log(res, "ser");
          if (res.code == 0) {
            that.$message.success("发送成功");
             // 60秒倒计时
            let time = 60;
            let timer = setInterval(() => {
              if (time <= 0) {
                that.disabled = false;
                that.loginMessage = "获取验证码";
                clearInterval(timer);
              } else {
              // 将按钮设置为不可点击状态
                that.disabled = true;
                that.loginMessage = time + "s后重试";
                time--;
              }
            }, 1000);
          } else {
            that.$message.error(res.message);
          }
        });
    },
    showPwd() {
      this.phone = "";
      this.mycode = "";
      this.password = "";
      this.newPassword = "";
    },

    
     YpSuccess(info){
        this.tokens = info.token
        this.$post('api/user/authenticate', {authenticate:info.authenticate, token:info.token}).then(res => {
        if (res.code == 0) {
          this.yp = false
          this.codeM()
        }else{
          this.$message.error("请刷新页面");
        }
      })
    },

  }
};
</script>

<style lang='scss' scoped >
.password {
  width: 100%;
  height: 780px;
  padding: 0 38px;
  position: relative;
  .password-top {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-top: 28px;
    p {
      position: relative;
      padding-left: 13px;
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0px;
        width: 4px;
        height: 17px;
        background: #3c7efe;
        border-radius: 2px;
      }
    }
  }
  .password-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .el-form {
         position: relative;
        .el-input {
          margin-bottom: 16px;
          border-radius: 12px;
          .el-icon-error {
            margin: 14px 7px 0 0;
          }
        }
        .buttom-code {
          position: absolute;
          top: 28%;
          right: 6px;
        }
    }
  }
}

//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
.bj {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
</style>